import { formatDateHourShort } from 'utils/dates';
import { useParams, useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';
const SingleRepresentative = ({ representative }) => {
  const cellphone = representative?.cellphone ?? representative?.electorData?.phoneNumber;
  return (
    <div>
      <div className="font-bold text-gray-800">{representative.electorData.code}</div>
      <div className="font-semibold text-gray-800">
        {representative.electorData.firstName} {representative.electorData.firstLastName}{' '}
        {representative.electorData.secondLastName}
      </div>
      <div className="text-sm text-gray-500">
        {representative.electorData.email} {cellphone ? `| ${cellphone}` : ''}
      </div>
    </div>
  );
};
const Representatives = ({ representatives }) => {
  const {
    mainRepresentative: main,
    alternateRepresentative: alternate,
    technicalRepresentative: technical,
  } = representatives;
  console.log('main.electorData', main.electorData);
  return (
    <div>
      <SingleRepresentative representative={main} />
      {alternate?.electorData ? (
        <SingleRepresentative representative={alternate} />
      ) : null}
      {technical?.electorData ? (
        <SingleRepresentative representative={technical} />
      ) : null}
    </div>
  );
};
export default function RepresentativesList({ representatives }) {
  const { electoralProcessId } = useParams();
  const history = useHistory();
  console.log('representatives', representatives);
  console.log('electoralProcessId', electoralProcessId);
  representatives = orderBy(
    representatives,
    ['joinedElections.0.electionType.name','joinedElections.0.electionType.groupKey','joinedElections.0.electionType.name', 'inscriptionList.correlative'],
    ['asc'],
  );
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    #
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Personeros
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Inscripción
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Estado
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Estado Inscripción
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    # de Lista
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Inscripción Personero
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Inscripción Lista
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {representatives.map((representative, i) => (
                  <tr key={representative._id}>
                    <td className="px-2 py-2 whitespace-nowrap text-center">{i + 1}</td>
                    <td className="px-1 py-2 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-2 pr-3">
                          <div className="text-sm text-gray-900">
                            <Representatives representatives={representative} />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      {representative.joinedElections?.[0]?.electionType.name || ''}
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          representative.status === 'CONFIRMED'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {representative.status === 'CONFIRMED'
                          ? 'Confirmada'
                          : 'Pendiente'}
                      </span>
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          representative.inscriptionList
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {representative.inscriptionList ? (
                          <div
                            className="hover:text-indigo-900 cursor-pointer"
                            onClick={() => {
                              history.push({
                                pathname: `/admin/procesos-electorales/${electoralProcessId}/personeros/${representative._id}`,
                              });
                            }}
                          >
                            Ver Inscripción
                          </div>
                        ) : (
                          'Pendiente'
                        )}
                      </span>
                    </td>

                    <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <span className="font-bold">
                        {representative.inscriptionList?.correlative}
                      </span>
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {representative.createdAt
                        ? formatDateHourShort(representative.createdAt)
                        : null}
                    </td>
                    <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      {representative.inscriptionList
                        ? formatDateHourShort(representative.inscriptionList.createdAt)
                        : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
