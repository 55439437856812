import { useParams, useHistory } from 'react-router-dom';
import { useEffect, useState, memo } from 'react';
import { format, parseISO } from 'date-fns';
import { Formik, Form, Field } from 'formik';
import { InputText } from 'components/atoms';
import { getElectoralProcesses } from 'api/electoralProcess';
import { registerRepresentative } from 'api/representative';
import { getElector } from 'api/elector';
import { Button } from 'components/atoms';
import toast from 'react-hot-toast';

const REPRESENTATIVE_RECORD = {
  EMPTY: 'EMPTY',
  VALID: 'VALID',
};
const Representative = memo(({ type, value, setFieldValue, electoralProcessCode }) => {
  const [statusButton, setStatusButton] = useState(null);
  const [statusRecord, setStatusRecord] = useState(REPRESENTATIVE_RECORD.EMPTY);
  const representativeTypes = {
    GENERAL: { name: 'Personero General', key: 'mainRepresentative', extra: '' },
    ALTERNO: {
      name: 'Personero Alterno',
      key: 'alternateRepresentative',
      extra: '(opcional)',
    },
    TECNICO: {
      name: 'Personero Técnico',
      key: 'technicalRepresentative',
      extra: '(opcional)',
    },
  };
  const representative = representativeTypes[type];
  const validateRepresentative = async () => {
    setStatusButton('LOADING');
    const { key } = representativeTypes[type];
    try {
      const elector = await getElector({
        code: value.code,
        email: value.email,
        electoralProcessCode,
      });
      setFieldValue(`${key}.firstName`, elector.firstName);
      setFieldValue(
        `${key}.lastName`,
        `${elector.firstLastName} ${elector.secondLastName}`,
      );
      setStatusRecord(REPRESENTATIVE_RECORD.VALID);
    } catch (err) {
      setFieldValue(`${key}.firstName`, '');
      setFieldValue(`${key}.lastName`, '');
      toast.error('No se encontró en el padrón');
    }
    setStatusButton(null);
  };
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
      <div>
        {representative.name} <span className="text-sm">{representative.extra}</span>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-2">
          <Field
            type="text"
            name={`${representative.key}.code`}
            label="DNI"
            placeholder="DNI"
            component={InputText}
            disabled={statusRecord === REPRESENTATIVE_RECORD.VALID}
          />
        </div>

        <div className="sm:col-span-3">
          <Field
            type="text"
            name={`${representative.key}.email`}
            label="Email Institucional *"
            placeholder="ejemplo@uni.pe"
            component={InputText}
            disabled={statusRecord === REPRESENTATIVE_RECORD.VALID}
          />
        </div>
        <div className="sm:col-span-1 flex items-end">
          {statusRecord === REPRESENTATIVE_RECORD.EMPTY ? (
            <Button onClick={validateRepresentative} status={statusButton}>
              Validar
            </Button>
          ) : (
            <Button onClick={() => setStatusRecord(REPRESENTATIVE_RECORD.EMPTY)}>
              Cambiar
            </Button>
          )}
        </div>
      </div>
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <Field
            type="text"
            name={`${representative.key}.firstName`}
            placeholder="---"
            disabled
            component={InputText}
          />
        </div>

        <div className="sm:col-span-3">
          <Field
            type="text"
            name={`${representative.key}.lastName`}
            placeholder="---"
            disabled
            component={InputText}
          />
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b sm:border-gray-200 sm:pb-5">
        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Celular <span className="text-red-500">*</span>
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <Field
              type="text"
              name={`${representative.key}.cellphone`}
              placeholder=""
              component={InputText}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
const RegistroPersonero = () => {
  const { electoralProcessCode } = useParams();
  const history = useHistory();
  const [electoralProcess, setElectoralProcess] = useState(null);

  useEffect(() => {
    const getElectoralProcess = async (electoralProcessCode) => {
      const electoralProcesses = await getElectoralProcesses();
      return electoralProcesses.find((e) => e.code === electoralProcessCode);
    };
    const setupInscription = async () => {
      const process = await getElectoralProcess(electoralProcessCode);
      console.log('process found', process);
      if (!!process) {
        process.startDate = format(parseISO(process.startDate), 'dd-MM-yyyy');
        process.endDate = format(parseISO(process.endDate), 'dd-MM-yyyy');
        setElectoralProcess(process);
      } else {
        alert('El proceso no existe');
      }
    };
    setupInscription();
  }, [electoralProcessCode]);
  const handleSubmit = async (values) => {
    try {
      await registerRepresentative({ ...values, electoralProcessCode });
      toast.success('Se registró con éxito, revisa su bandeja de correo');
      history.push(`/login/${electoralProcessCode}`);
    } catch (err) {
      console.error(err);
      if (err?.code) {
        toast.error(err.message);
      } else {
        toast.error('Error al crear padron');
      }
    }
  };
  return (
    <div className="max-w-xl mx-auto mt-20 mb-20">
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Registro de personeros
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Proceso: {electoralProcess?.name}
              </p>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Fecha Inicio: {electoralProcess?.startDate} Fecha Fin:{' '}
                {electoralProcess?.endDate}
              </p>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Inscriba a todos los personeros antes de poder inscribir su lista
              </p>
              <p className="mt-1 max-w-2xl text-xs text-gray-500">
                * Estos campos son obligatorios
              </p>
            </div>
            <Formik
              initialValues={{
                mainRepresentative: {
                  email: '',
                  code: '',
                  cellphone: '',
                  firstName: '',
                  lastName: '',
                },
                alternateRepresentative: {
                  email: '',
                  code: '',
                  cellphone: '',
                  firstName: '',
                  lastName: '',
                },
                technicalRepresentative: {
                  email: '',
                  code: '',
                  cellphone: '',
                  firstName: '',
                  lastName: '',
                },
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Representative
                    type="GENERAL"
                    value={values.mainRepresentative}
                    electoralProcessCode={electoralProcessCode}
                    setFieldValue={setFieldValue}
                  />
                  <Representative
                    type="ALTERNO"
                    value={values.alternateRepresentative}
                    electoralProcessCode={electoralProcessCode}
                    setFieldValue={setFieldValue}
                  />
                  <Representative
                    type="TECNICO"
                    value={values.technicalRepresentative}
                    electoralProcessCode={electoralProcessCode}
                    setFieldValue={setFieldValue}
                  />
                  <div className="pt-5">
                    <div className="flex justify-end">
                      <Button type="submit">Registrar</Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistroPersonero;
