import useQuery from 'hooks/useQuery';
import { InputText, Button } from 'components/atoms';
import { useEffect, useState } from 'react';
import { resetPassword } from '../../../api/representative';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

const RestorePassword = () => {
  const query = useQuery();
  const token = query.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validRequest, setValidRequest] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const handleClick = async () => {
    try {
      await resetPassword({ token, password });
      toast.success('Passsword reseteada exitosamente');
      setPasswordResetSuccess(true);
    } catch (err) {
      console.error(err);
      toast.error('Ocurrió un error al restablecer tu password');
    }
  };

  useEffect(() => {
    const areValidPasswords = () => {
      return password.length >= 8 && password === confirmPassword;
    };
    if (areValidPasswords()) {
      setValidRequest(true);
    } else {
      setValidRequest(false);
    }
  }, [password, confirmPassword]);
  return (
    <div className="w-96 mx-auto mt-10">
      {passwordResetSuccess ? (
        <div>
          <div className="mt-10">
            Password cambiada correctamente, dirígase a la página del login de su
            respectivo proceso electoral.
          </div>
          <div className="mt-6">
            <Link to="/">
              <Button>Ir a página principal</Button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          {token?.length > 0 ? (
            <div className="mt-2">
              <div>Ingrese su nueva password</div>
              <InputText
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Nueva password"
              />
              {password.length > 0 && password.length <= 7 ? (
                <div className="text-red-500 text-sm mt-1">
                  La password debe contener al menos 8 caracteres
                </div>
              ) : null}
              <InputText
                type="password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder="Confirmar password"
              />
              {password.length > 0 &&
              confirmPassword.length > 0 &&
              password !== confirmPassword ? (
                <div className="text-red-500 text-sm mt-1">
                  Las contraseñas no coinciden
                </div>
              ) : null}
              <Button className="mt-4" onClick={handleClick} disabled={!validRequest}>
                Restablecer
              </Button>
            </div>
          ) : (
            'Token Inválido'
          )}
        </div>
      )}
    </div>
  );
};

export default RestorePassword;
