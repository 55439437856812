import {InputResponse} from 'components/molecules';
const Candidate = ({ candidate }) => {
  console.log('candidate', candidate);
  const normalInputs = Object.keys(candidate.inputs)
    .filter((key) => candidate.inputs[key].inputType !== 'FILE')
    .map((key) => ({ ...candidate.inputs[key], key }));
  const fileInputs = Object.keys(candidate.inputs)
    .filter((key) => candidate.inputs[key].inputType === 'FILE')
    .map((key) => ({ ...candidate.inputs[key], key }));
  return (
    <div className="p-4 rounded-3xl bg-gray-200 mb-2 text-gray-800 w-full">
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="w-1/2"></th>
            <th className="w-1/2"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span className="font-bold">Candidato: </span>
            </td>
            <td className="pl-3">{candidate.candidateKey}</td>
          </tr>
          <tr>
            <td>
              {' '}
              <span className="font-bold">Código UNI:</span>
            </td>
            <td className="pl-3">{candidate.electorData.code}</td>
          </tr>
          <tr>
            <td>
              {' '}
              <span className="font-bold">Nombre Completo:</span>
            </td>
            <td className="pl-3">
              {candidate.electorData.firstName} {candidate.electorData.firstLastName}{' '}
              {candidate.electorData.secondLastName}
            </td>
          </tr>
          {candidate.electorData.faculty ? (
            <tr>
              <td>
                {' '}
                <span className="font-bold">Facultad:</span>
              </td>
              <td className="pl-3">{candidate.electorData.faculty}</td>
            </tr>
          ) : null}
          {candidate.electorData.degree ? (
            <tr>
              <td>
                {' '}
                <span className="font-bold">Grado:</span>
              </td>
              <td className="pl-3">{candidate.electorData.degree}</td>
            </tr>
          ) : null}
          {candidate.electorData.category ? (
            <tr>
              <td>
                {' '}
                <span className="font-bold">Categoría: </span>
              </td>
              <td className="pl-3">{candidate.electorData.category}</td>
            </tr>
          ) : null}
          {candidate.electorData.ageYears ? (
            <tr>
              <td>
                {' '}
                <span className="font-bold">Edad: </span>
              </td>
              <td className="pl-3">{candidate.electorData.ageYears} años</td>
            </tr>
          ) : null}
          {normalInputs.map((input) => (
            <InputResponse key={input.key} input={input} />
          ))}
          {fileInputs.map((input) => (
            <InputResponse key={input.key} input={input} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Candidate