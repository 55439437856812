import axios from './index';

export const loginAdmin = async ({ username, password }) => {
  try {
    const { data } = await axios.post('/admin/login', { username, password });
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};