import axios from './index';

export const submitInscription = async (payload) => {
  try {
    const { data } = await axios.post('/inscription-list', payload);
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const getInscription = async () => {
  try {
    const { data } = await axios.get('/inscription-list/by-representative');
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const getInscriptionAdmin = async (representativeId) => {
  try {
    const { data } = await axios.get('/inscription-list/by-admin', {
      params: { representativeId },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const getReport = async (electoralProcessId) => {
  try {
    const { data, headers } = await axios.get('/inscription-list/report', {
      params: { electoralProcessId },
      responseType: 'blob',
    });
    const fileName = headers['content-disposition'].match(
      /filename="([a-zA-Z0-9\-.:_]+)"/,
    )[1];

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};
