import { CalendarIcon } from '@heroicons/react/solid';
import { Link, useHistory } from 'react-router-dom';
import { formatDateHourDetailed } from 'utils/dates';
import { useState } from 'react';

const DateInitEnd = ({ title, start, end }) => {
  return (
    <div className="w-80">
      <div className="text-sm">{title}</div>
      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
        <CalendarIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span>Inicio {formatDateHourDetailed(start)}</span>
      </div>
      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
        <CalendarIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span>Fin {formatDateHourDetailed(end)}</span>
      </div>
    </div>
  );
};
const ElectionLists = ({ elections }) => {
  const [showMore, setShowMore] = useState(false);
  const buildGroup = (elections) => {
    const electionsGroup = [];
    elections.forEach((election) => {
      const index = electionsGroup.findIndex(
        (e) => e.groupKey === election.electionType.groupKey,
      );
      if (index !== -1) {
        electionsGroup[index].elections.push(election.electionType);
      } else {
        electionsGroup.push({
          groupKey: election.electionType.groupKey,
          elections: [election.electionType],
        });
      }
    });
    return electionsGroup;
  };
  return (
    <div>
      <ul>
        {buildGroup(elections.slice(0, showMore ? 100 : 3)).map((e) => {
          return (
            <div key={e.groupKey} className="text-gray-800 mb-1">
              <span className="mr-2 mt-4 font-bold text-gray-700 text-sm">
                {e.groupKey}
              </span>
              <p className="text-justify">
                {e.elections
                  .filter((e) => e.showDetail)
                  .map((e) => e.name)
                  .map((e) => {
                    return <div key={e}>{e}</div>;
                  })}
              </p>
            </div>
          );
        })}
      </ul>
      <div
        className="underline text-blue-500 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setShowMore((prev) => !prev);
        }}
      >
        {showMore ? 'Mostrar menos' : 'Mostrar más'}
      </div>
    </div>
  );
};
const ElectoralProcessList = ({ electoralProcesses, type }) => {
  const history = useHistory();
  const getLink = (electoralProcess) => {
    if (type === 'ADMIN') {
      return `procesos-electorales/${electoralProcess.code}/`;
    } else {
      return `/login/${electoralProcess.code}`;
    }
  };

  return (
    <ul className="divide-y divide-gray-200">
      {electoralProcesses?.length ? (
        electoralProcesses.map((electoralProcess) => {
          const isActive = new Date(electoralProcess.endDate) >= new Date();
          return (
            <li key={electoralProcess.code}>
              <div className="bg-gray-50 shadow overflow-hidden sm:rounded-md mx-4">
                <div className="px-4 py-4 sm:px-6">
                  <div className="flex items-center justify-between">
                    <div className='flex-shrink w-9/12'>
                      <Link
                        to={getLink(electoralProcess)}
                        className="block hover:bg-gray-50"
                      >
                        <p className="text-base font-bold underline text-indigo-600">
                          {electoralProcess.name}
                        </p>
                      </Link>
                    </div>

                    <div className="flex-none ml-2 flex-shrink-0 flex gap-x-1">
                      {isActive ? (
                        <div
                          className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-500 text-white cursor-pointer"
                          onClick={() => {
                            history.push(`/padron/${electoralProcess.code}`);
                          }}
                        >
                          Ver padrón
                        </div>
                      ) : null}

                      <div
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          isActive ? 'bg-green-100' : 'bg-red-100'
                        }  ${isActive ? 'text-green-800' : 'text-red-800'} `}
                      >
                        {isActive ? 'Activo' : 'Inactivo'}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row gap-x-4 gap-y-2 flex-wrap">
                    <DateInitEnd
                      title="Duración del proceso"
                      start={electoralProcess.startDate}
                      end={electoralProcess.endDate}
                    />
                    <DateInitEnd
                      title="Registro de candidaturas"
                      start={electoralProcess.inscriptionStartDate}
                      end={electoralProcess.inscriptionEndDate}
                    />
                    <DateInitEnd
                      title="Publicación de documentos de candidatos"
                      start={electoralProcess.publicStartDate}
                      end={electoralProcess.publicEndDate}
                    />
                  </div>
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      <ElectionLists elections={electoralProcess.elections} />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })
      ) : (
        <div className="text-gray-700 text-center">No hay procesos activos</div>
      )}
    </ul>
  );
};

export default ElectoralProcessList;
