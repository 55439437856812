import axios from './index';

export const getElectoralProcesses = async () => {
  try {
    const { data } = await axios.get('/electoral-process');
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getElectoralProcess = async (processCode) => {
  try {
    const { data } = await axios.get('/electoral-process');
    return data.find(({ code }) => code === processCode);
  } catch (err) {
    console.error(err);
    throw err;
  }
};
