import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'components/atoms';
import RepresentativesList from './RepresentativesList';
import { useEffect, useState } from 'react';
import { getRepresentatives } from 'api/representative';
import useQuery from 'hooks/useQuery';
const Representatives = () => {
  const history = useHistory();
  const query = useQuery();
  const electionType = query.get('electionType');
  const { electoralProcessId } = useParams();
  const [representatives, setRepresentatives] = useState([]);
  useEffect(() => {
    const init = async () => {
      const representativesData = await getRepresentatives({
        electoralProcessCode: electoralProcessId,
      });
      if (electionType) {
        setRepresentatives(
          representativesData.filter(
            (e) => e.inscriptionList?.electionId === electionType,
          ),
        );
      } else {
        setRepresentatives(representativesData);
      }
    };
    init();
  }, [electionType]);
  return (
    <div>
      <div className="mb-3">
        Código Proceso Electoral:{' '}
        <span className="text-gray-600">{electoralProcessId}</span>
      </div>
      <div className="text-3xl">Lista de Personeros</div>
      <div className="my-3">
        <RepresentativesList representatives={representatives} />
      </div>
      <div>
        <Button onClick={() => history.goBack()}>Volver</Button>
      </div>
    </div>
  );
};

export default Representatives;
