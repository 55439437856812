import { useState } from 'react';
import { Button } from 'components/atoms';
const candidateType = {
  TEACHER: 'Profesor',
  STUDENT: 'Estudiante',
};

const RequerimentsList = ({ requirements = [], additionalRequirements = [] }) => {
  console.log('additionalRequirements');
  const requirementsToShow = requirements
    ?.filter((rule) => rule.show)
    .concat(additionalRequirements.map((req) => ({ description: req })));
  const [showReqs, setShowReqs] = useState(false);
  console.log('requirements', requirements);
  return (
    <div className="ml-4">
      {requirementsToShow.length ? (
        <>
          {!showReqs ? (
            <span
              className="text-gray-800 text-opacity-75 underline cursor-pointer"
              onClick={() => setShowReqs(true)}
            >
              Mostrar Requisitos
            </span>
          ) : (
            <>
              <div>Requisitos</div>
              <ul className="list-disc list-inside">
                {requirementsToShow.map((rule) => {
                  return (
                    <li
                      key={rule.description}
                      className="text-gray-700 text-opacity-75 text-base"
                    >
                      {rule.description}
                    </li>
                  );
                })}
              </ul>
              <span
                className="text-gray-800 text-opacity-75 underline cursor-pointer"
                onClick={() => setShowReqs(false)}
              >
                Ocultar Requisitos
              </span>
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

const ElectionItem = ({ election, setSelectedElection, setDisplayModal }) => {
  const [showMore, setShowMore] = useState(false);
  const minShow = 5;
  return (
    <div className="border border-gray-300 rounded-3xl p-5 bg-white mb-3">
      <div>
        Tipo:{' '}
        <span className="font-bold text-gray-700">{election.electionType.name}</span>
      </div>
      <div className="mt-2">
        <div>Candidatos</div>
        <div>
          {election.electionType.candidates
            .slice(0, showMore ? 1000 : minShow)
            .map((e, i) => {
              return (
                <div key={e.name}>
                  <div>
                    {i + 1}. {e.name}
                    {e.candidateType === 'TEACHER'
                      ? ''
                      : `- ${candidateType[e.candidateType]}`}
                  </div>
                  <RequerimentsList
                    requirements={e.rules}
                    additionalRequirements={e.additionalRequirements}
                  />
                </div>
              );
            })}
          {election.electionType.candidates.length > minShow ? (
            <div
              className="font-blue-500 underline cursor-pointer"
              onClick={() => setShowMore(!showMore)}
            >
              Mostrar {showMore ? 'menos' : 'más'}
            </div>
          ) : null}
        </div>
      </div>
      {setSelectedElection ? (
        <Button
          onClick={() => {
            setDisplayModal(true);
            setSelectedElection(election._id);
          }}
        >
          Seleccionar
        </Button>
      ) : null}
      <div></div>
    </div>
  );
};

export default ElectionItem;
