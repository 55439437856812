import { useState } from 'react';
import ElectionItem from './electionItem';
import { Modal } from 'components/molecules';
import { joinToElection } from 'api/representative';

import toast from 'react-hot-toast';
const ElectionSelector = ({ elections, checkForJoinedElections }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedElection, setSelectedElection] = useState(null);
  const handleConfirm = async (electionId) => {
    try {
      await joinToElection({ electionId });
      setDisplayModal(false);
      await checkForJoinedElections();
      toast.success('Registro de elección exitoso');
    } catch (err) {
      setDisplayModal(false);
      toast.error('Error al unirse a una elección');
    }
  };

  return (
    <>
      <div>
        {elections.map((e) => (
          <ElectionItem
            key={e._id}
            election={e}
            setSelectedElection={setSelectedElection}
            setDisplayModal={setDisplayModal}
          />
        ))}
      </div>
      <Modal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        handleConfirm={() => {
          handleConfirm(selectedElection);
        }}
        title="Confirmación de la selección"
        description="Estás seguro que quiere inscribir esta candidatura? Esto hará que
                      solo sea visible esta candidatura en tu siguiente ingreso, no podrás
                      escoger otra opción de candidatura"
      />
    </>
  );
};

export default ElectionSelector;
