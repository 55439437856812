import PropTypes from 'prop-types';
const InputText = ({ field, form, label, ...props }) => {
  return (
    <div>
      {label ? (
        <label className="block text-sm font-medium text-gray-700">{label}</label>
      ) : null}
      <input
        className={`mt-1 focus:ring-green-200 focus:border-green-200 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
          props.disabled ? 'disabled:opacity-70 bg-gray-200' : ''
        }`}
        {...field}
        {...props}
      />
    </div>
  );
};
InputText.propTypes = {
  label: PropTypes.string,
};

export default InputText;
