import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute';
import {
  UserLoginPage,
  RepresentativeRegisterPage,
  RecoverPassword,
  RestorePassword,
  ElectoralConsultPage,
  Home,
  MenuRepresentativePage,
  MenuAdminPage,
  ConfirmPage,
  AdminLoginPage,
  LogoutPage,
} from './pages';

import { Toaster } from 'react-hot-toast';
const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/registro-personero/:electoralProcessCode">
          <RepresentativeRegisterPage />
        </Route>
        <Route exact path="/recuperar-password/:electoralProcessCode">
          <RecoverPassword />
        </Route>
        <Route exact path="/restablecer-password">
          <RestorePassword />
        </Route>
        
        <Route exact path="/padron/:electoralProcessCode">
          <ElectoralConsultPage />
        </Route>
        <Route exact path="/login/:electoralProcessCode">
          <UserLoginPage />
        </Route>
        <Route exact path="/confirmar">
          <ConfirmPage />
        </Route>
        <Route path="/personero">
          <ProtectedRoute component={MenuRepresentativePage} role="REPRESENTATIVE" />
        </Route>

        <Route exact path="/admin/login">
          <AdminLoginPage />
        </Route>
        <Route path="/admin">
          <ProtectedRoute component={MenuAdminPage} role="ADMIN" />
        </Route>
        <Route exact path="/logout">
          <LogoutPage />
        </Route>
        <Route exact path="/404">
          <div className="p-5">Pagina no Encontrada</div>
        </Route>
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
      <Toaster
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 4000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </Router>
  );
};

export default App;
