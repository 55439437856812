import { useState, useEffect } from 'react';
import { getElectoralProcesses } from 'api/electoralProcess';
import { ElectoralProcessList } from 'components/molecules';

const ProcesosElectorales = () => {
  const [electoralProcesses, setElectoralProcesses] = useState([]);
  useEffect(() => {
    const listarProcesos = async () => {
      const processes = await getElectoralProcesses();
      setElectoralProcesses(processes);
    };
    listarProcesos();
  }, []);
  return (
    <div className="mt-2 max-w-4xl mx-auto">
      <div className="mb-6">
        <p className="text-3xl text-center">Lista de Procesos Electorales</p>
      </div>
      <ElectoralProcessList electoralProcesses={electoralProcesses} type="ADMIN"/>
    </div>
  );
};

export default ProcesosElectorales;
