const baseAWS = 'https://inscripciones-ceuni.s3.amazonaws.com/';
const InputResponse = ({ input }) => {
  const { inputType } = input;
  if (inputType === 'TEXT') {
    return (
      <tr>
        <td>
          <span className="font-bold">{input.label}: </span>
        </td>
        <td className="pl-3">{input.value}</td>
      </tr>
    );
  } else if (inputType === 'FILE') {
    return (
      <tr>
        <td>
          <span className="font-bold">{input.label}: </span>
        </td>
        <td className="pl-3">
          <a
            className="text-blue-500 underline"
            href={baseAWS + input.value}
            target="_blank"
            rel="noreferrer"
          >
            Click para abrir archivo
          </a>
        </td>
      </tr>
    );
  } else if (inputType === 'CHECK') {
    return (
      <tr>
        <td>
          <span className="font-bold">{input.label}: </span>
        </td>
        <td className="pl-3">{input.value === 'TRUE' ? 'SÍ' : 'NO'}</td>
      </tr>
    );
  }
  return <div>{JSON.stringify(input)}</div>;
};

export default InputResponse;
