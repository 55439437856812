import { useEffect, useState } from 'react';
import { getProfile, uploadSupportFile } from 'api/representative';
import { CloudUploadIcon } from '@heroicons/react/outline';
import toast from 'react-hot-toast';
import { formatDateHour } from 'utils/dates';
import { Button } from 'components/atoms';
import { Representative } from 'components/molecules';
const Profile = () => {
  const [representatives, setRepresentatives] = useState(null);
  const [file, setFile] = useState(null);
  const onFileChange = async (event) => {
    setFile(event.target.files[0]);
  };
  const uploadFile = async () => {
    const formData = new FormData();

    formData.append('supportDocument', file, file.name);

    console.log(file);
    try {
      await uploadSupportFile(formData);
      toast.success('Archivo subido con éxito');
      setFile(null);
    } catch (err) {
      console.error(err);
      toast.error('Error subiendo archivo');
    }
  };
  useEffect(() => {
    const run = async () => {
      const data = await getProfile();
      setRepresentatives(data);
    };
    if (!representatives) run();
  }, [representatives]);
  return (
    <div className="text-gray-700">
      <strong>Informacion general:</strong>
      {representatives ? (
        <>
          <div className="mb-2">
            Código Proceso Electoral: {representatives.electoralProcessCode?.slice(0, 6)}{' '}
            - <strong>{representatives.electoralProcess?.name}</strong>
            <p>Fecha Registro: {formatDateHour(representatives.createdAt)}</p>
            <p>
              Fecha Confirmación Registro: {formatDateHour(representatives.confirmedAt)}
            </p>
          </div>
          <div className="font-bold">Personero Principal:</div>
          <Representative representative={representatives.mainRepresentative} />
          <div className="mt-2 font-bold">Personero Alternativo:</div>

          <Representative representative={representatives.alternateRepresentative} />

          <div className="mt-2 font-bold">Personero Técnico:</div>

          <Representative representative={representatives.technicalRepresentative} />
          <div className="mt-2">
            <strong>Documento sustentatorio de personeros:</strong>
            <div>
              Suba formato G1 correspondiente (.pdf)
            </div>
            {representatives.supportDocument ? (
              <>
                <div>
                  Archivo subido:{' '}
                  <a
                    className="text-blue-500 underline"
                    href={representatives.supportDocument.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Click aquí para descargar
                  </a>
                </div>
              </>
            ) : null}
          </div>

          <div className="w-48 mt-2">
            <label className="mb-2 w-48 flex flex-col items-center px-2 py-2 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-red-900 hover:text-white text-red-900 ease-linear transition-all duration-150">
              <CloudUploadIcon className="h-8 w-8" aria-hidden="true" />
              <span className="mt-2 text-base leading-normal">Suba archivo</span>
              <input
                type="file"
                className="hidden"
                onChange={onFileChange}
                accept=".pdf"
              />
            </label>
            {file ? <Button onClick={uploadFile}>Cargar archivo</Button> : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Profile;
