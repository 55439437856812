import { InputText, Button } from 'components/atoms';
import { useState } from 'react';
import { sendResetPasswordRequest } from '../../../api/representative';
import toast from 'react-hot-toast';

const RecoverPassword = () => {
  const [email, setEmail] = useState('');
  const [statusButton, setStatusButton] = useState('');
  const handleClick = async () => {
    setStatusButton('LOADING');
    try {
      await sendResetPasswordRequest({ email });
      toast.success('Correo enviado');
    } catch (err) {
      console.error(err);
    }
    setStatusButton('SUCCESS');
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>Recuperar password de personero</div>
        <div>
          <InputText
            placeholder="Ingrese su correo"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div className="mt-2">
          <Button onClick={handleClick} disabled={statusButton === 'LOADING'}>
            Solicitar nueva password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
