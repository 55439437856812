const Representative = ({ representative }) => {
  return (
    <div className="ml-3">
      {representative?.electorData ? (
        <>
          <p>
            {representative?.electorData.firstName}{' '}
            {representative?.electorData.firstLastName}{' '}
            {representative?.electorData.secondLastName}
          </p>
          <p>Código UNI: {representative?.electorData.code}</p>
          <p>
            DNI:
            {representative?.electorData.dni}
          </p>
          <p>Celular Registrado: {representative?.cellphone}</p>
        </>
      ) : (
        <p>No presenta personero alternativo</p>
      )}
    </div>
  );
};

export default Representative;