import axios from './index';

export const getElectionsAdmin = async ({ electoralProcessCode }) => {
  try {
    const { data } = await axios.get('/election', { params: { electoralProcessCode } });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getElections = async () => {
  try {
    const { data } = await axios.get('/election');
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getElectionById = async (electionId) => {
  try {
    const { data } = await axios.get('/election');
    return data.find((e) => e._id === electionId);
  } catch (err) {
    console.error(err);
    throw err;
  }
};
