import { useState, useEffect } from 'react';
import { Button } from '../components/atoms/';
import { useParams, useHistory } from 'react-router-dom';
import { getElectoralProcess } from 'api/electoralProcess';
import { getElectorPublic } from 'api/elector';
import { REQUEST_STATUS } from 'utils/consts';

const ElectoralConsult = () => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [elector, setElector] = useState(null);
  const [queryState, setQueryState] = useState('');
  const [electoralProcess, setElectoralProcess] = useState();
  const { electoralProcessCode } = useParams();
  const history = useHistory();

  const classNameList = 'font-bold';
  const getElector = async () => {
    setQueryState(REQUEST_STATUS.LOADING);
    setElector(null);
    try {
      const elector = await getElectorPublic({ electoralProcessCode, code, email });
      setElector(elector);
    } catch (err) {
      console.error(err);
    }
    setQueryState(REQUEST_STATUS.LOADED);
  };
  useEffect(() => {
    console.log('electoralProcessCode', electoralProcessCode);
    const init = async () => {
      const electoralProcess = await getElectoralProcess(electoralProcessCode);
      setElectoralProcess(electoralProcess);
    };
    init();
  }, [electoralProcessCode]);
  return (
    <div className="min-h-screen">
      <div className="grid place-items-center mt-6">
        <div className="text-4xl">Consulta padrón</div>
        <div className="mt-2">
          <div className="mx-auto w-9/12 text-center text-xl">{electoralProcess?.name}</div>
        </div>
        <div className="mt-10">
          Ingrese su codigo UNI para saber si está habilitado en este proceso
        </div>
      </div>
      <div className="mt-8 max-w-sm mx-auto">
        <div>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={code}
            onChange={(event) => setCode(event.target.value)}
            placeholder="Código UNI"
          />
        </div>
        <div className="mt-2">
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="Correo UNI"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                getElector();
              }
            }}
          />
        </div>

        <div className="mt-4 flex gap-x-4">
          <Button onClick={() => history.goBack()} buttonType="secondary">
            Regresar
          </Button>
          <Button onClick={getElector}>Consultar</Button>
        </div>
        <div className="mt-2">
          {queryState === 'LOADING' ? <div>Buscando...</div> : null}
          {queryState === 'LOADED' && !elector ? (
            <div>Registro no encontrado en el padrón.</div>
          ) : null}
        </div>
        {elector ? (
          <div className='mt-10'>
            <div>
              <span className={classNameList}>DNI:</span> {elector.dni}
            </div>
            <div>
              <span className={classNameList}>Código UNI:</span> {elector.code}
            </div>
            <div>
              <span className={classNameList}>Email: </span>
              {elector.email}
            </div>
            <div>
              <span className={classNameList}>Nombres:</span> {elector.firstName}
            </div>
            <div>
              <span className={classNameList}>Apellido Paterno:</span>{' '}
              {elector.firstLastName}
            </div>
            <div>
              <span className={classNameList}>Apellido Materno:</span>{' '}
              {elector.secondLastName}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ElectoralConsult;
