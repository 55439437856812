import axios from './index';

export const registerRepresentative = async (payload) => {
  try {
    const { data } = await axios.post('/representative', payload);
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const sendResetPasswordRequest = async ({ email }) => {
  try {
    const { data } = await axios.post('/representative/request-reset-password', {
      email,
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const resetPassword = async ({ token, password }) => {
  try {
    const { data } = await axios.post('/representative/reset-password', {
      token,
      newPassword: password,
      verifyPassword: password,
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const confirmInscription = async ({ confirmationToken }) => {
  try {
    const { data } = await axios.post('/representative/confirm', { confirmationToken });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const loginRepresentative = async ({ email, password }) => {
  try {
    const { data } = await axios.post('/representative/login', { email, password });
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const getProfile = async () => {
  try {
    const { data } = await axios.get('/representative/profile');
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const uploadSupportFile = async (formData) => {
  try {
    const { data } = await axios.put('/representative/support-document', formData);
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const joinToElection = async ({ electionId }) => {
  try {
    const { data } = await axios.post('/representative/join', { electionId });
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const uploadTempFile = async (formData) => {
  try {
    const { data } = await axios.post('/temporary-file', formData);
    return data;
  } catch (err) {
    console.error(err);
    throw err.response && err.response.data;
  }
};

export const getRepresentatives = async ({ electoralProcessCode }) => {
  try {
    const { data } = await axios.get('/representative', {
      params: { electoralProcessCode },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
