import { Panel } from 'components/atoms';
const ErrorList = ({ errors }) => {
  return (
    <ul>
      {errors.map((error) => {
        return <li>- {error.message}</li>;
      })}
    </ul>
  );
};
const ErrorPanel = ({ errors, type = 'CANDIDATE' }) => {
  console.log('Errors', errors, type);
  return (
    <Panel borderColor="border-red-300">
      <div className="text-red-600 font-bold">Error en la inscripción</div>
      {type === 'CANDIDATES' ? (
        <>
          {errors.map((errors) => (
            <ErrorList errors={errors} />
          ))}
        </>
      ) : (
        <ErrorList errors={errors} />
      )}
    </Panel>
  );
};

export default ErrorPanel;
