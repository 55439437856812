import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from 'components/atoms';
import ElectionTypeList from './ElectionTypeList';
import { getElectionsAdmin } from 'api/election';
import { getReport } from 'api/inscriptionList';
const ButtonToRepresentatives = () => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(`personeros`)}>
      Revisar Lista de Personeros Inscritos
    </Button>
  );
};
const ElectoralProcess = () => {
  const { electoralProcessId } = useParams();
  const [elections, setElections] = useState([]);
  const downloadReport = async (electoralProcessId) => {
    await getReport(electoralProcessId);
  };
  useEffect(() => {
    const init = async () => {
      const electionsData = await getElectionsAdmin({
        electoralProcessCode: electoralProcessId,
      });
      setElections(electionsData);
    };
    init();
  }, []);
  return (
    <div>
      <div className="text-4xl">Detalle del proceso electoral</div>
      <div className="my-3">
        {/*Código: <span className="text-gray-600">{electoralProcessId}</span>*/}
      </div>
      <div className="flex mb-2 space-x-5">
        <ButtonToRepresentatives />
        <Button onClick={() => downloadReport(electoralProcessId)}>
          Descargar reporte
        </Button>
      </div>
      <div className="mb-2">
        <ElectionTypeList elections={elections} />
      </div>
      <div>
        <ButtonToRepresentatives />
      </div>
    </div>
  );
};

export default ElectoralProcess;
