import { LockClosedIcon } from '@heroicons/react/solid';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { InputText } from 'components/atoms';
import { loginRepresentative } from 'api/representative';
import toast from 'react-hot-toast';
const LoginPage = () => {
  const history = useHistory();
  const { electoralProcessCode } = useParams();
  const handleSubmit = async (values) => {
    try {
      const { expires, token } = await loginRepresentative(values);
      localStorage.setItem('token', token);
      localStorage.setItem('expires', expires);
      localStorage.setItem('role', 'REPRESENTATIVE');
      history.push('/personero');
    } catch (err) {
      if (err.code) {
        toast.error(err.message);
      } else {
        toast.error('Error al hacer login');
      }
    }
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-60 w-auto" src="/ceuni_logo.png" alt="CEUNI UNI" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Login de Personero
          </h2>
        </div>
        <Formik initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form className="mt-8 space-y-6">
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Correo"
                    component={InputText}
                  />
                </div>
                <div>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    component={InputText}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <p className="mt-2 text-sm text-gray-600">
                    Aún no te registraste?{' '}
                    <Link
                      to={`/registro-personero/${electoralProcessCode}`}
                      className="font-medium text-red-800 hover:text-indigo-500"
                    >
                      Hazlo haciendo click aquí
                    </Link>
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    Olvidate tu contraseña?{' '}
                    <Link
                      to={`/recuperar-password/${electoralProcessCode}`}
                      className="font-medium text-red-800 hover:text-indigo-500"
                    >
                      click aquí
                    </Link>
                  </p>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-red-300 group-hover:text-red-400"
                      aria-hidden="true"
                    />
                  </span>
                  Login
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LoginPage;
