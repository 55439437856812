import { CloudUploadIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from 'components/atoms';
import { uploadTempFile } from 'api/representative';
const InputFile = ({
  mimeTypes,
  url,
  label,
  name,
  electionId,
  candidateKey,
  setFieldValue,
}) => {
  console.log('INPUT FILE', name);
  console.log("MIMETYPE", mimeTypes)
  const [file, setFile] = useState(null);
  const onFileChange = async (event) => {
    setFile(event.target.files[0]);
  };
  const uploadFile = async () => {
    const [inputKey] = name.split('.').slice(-1);
    const formData = new FormData();
    console.log('FILE', file);
    formData.append('temporary', file, file.name);
    formData.append('electionType', electionId);
    formData.append('candidateKey', candidateKey);
    formData.append('inputKey', inputKey);

    try {
      const response = await uploadTempFile(formData);
      setFieldValue(`${name}.value`, response.key);
      setFieldValue(`${name}.url`, response.url);
      toast.success('Archivo subido con éxito');
      setFile(null);
    } catch (err) {
      console.error(err);
      toast.error('Error subiendo archivo');
    }
  };
  return (
    <div>
      {label ? (
        <label className="block text-sm font-medium text-gray-700">{label}</label>
      ) : null}
      {url ? (
        <div>
          Archivo subido:{' '}
          <a className="underline" href={url} target="_blank" rel="noreferrer">
            Click aquí para descargar
          </a>
        </div>
      ) : null}
      <div className="w-64">
        <label className="mb-2 w-48 flex flex-col items-center px-1 py-2 bg-white rounded-md shadow-md tracking-wide uppercase border border-blue cursor-pointer hover:bg-red-900 hover:text-white text-red-900 ease-linear transition-all duration-150">
          <CloudUploadIcon className="h-8 w-8" aria-hidden="true" />
          <span className="mt-2 text-base leading-normal">Subir {mimeTypes.join(', ')}</span>
          <input type="file" className="hidden" onChange={onFileChange} accept=".pdf" />
        </label>
        {file ? <Button onClick={uploadFile}>Cargar archivo</Button> : null}
      </div>
    </div>
  );
};

export default InputFile;
