import { getElectoralProcess } from 'api/electoralProcess';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Operator = {
  eq: '=',
  lt: '<',
  lte: '<=',
  gt: '>',
  gte: '>=',
  ne: '≠',
  in: 'en',
};

const ElectorType = {
  TEACHER: 'Docente',
  STUDENT: 'Estudiante',
};

const Field = {
  type: 'Tipo',
  faculty: 'Facultad',
  // Docentes
  category: 'Categoría',
  condition: 'Condition',
  ageYears: 'Edad en años',
  license: 'Licencia',
	serviceTimeYears:"Años de servicio",
	degree: "Grado",
  // Estudiantes
  academicPerformance: 'Es tercio',
  totalApprovedCredits: 'Creditos totales aprobados',
  lastPeriod: 'Último periodo',
};

const Parameter = {
  type: ElectorType,
  category: {
    AS: 'Asociado',
    PR: 'Principal',
    AX: 'Auxiliar',
  },
  condition: {
    N: 'Nombrado',
    C: 'Contratado',
  },
  license: {
    SG: 'Sin gose de haber',
    CG: 'Con gose de haber',
    SU: 'Suspendido',
  },
};

const displayParameter = (field, parameter) => {
  if (Array.isArray(parameter)) {
    return parameter.join(', ');
  }
  return Parameter[field]?.[parameter] ?? parameter;
};

const Visor = () => {
  const { electoralProcessId } = useParams();
  const [elections, setElections] = useState([]);
  useEffect(() => {
    const init = async () => {
      const electoralProcess = await getElectoralProcess(electoralProcessId);
      setElections(electoralProcess.elections);
    };
    init();
  }, [electoralProcessId]);
  return (
    <div>
      <div>
        {elections.map(({ electionType }, index) => {
          return (
            <div>
              <div>
                {index + 1}. <span className="font-bold">{electionType.name}</span>
              </div>
              <div className="ml-6">
                {electionType.candidates.map((candidate) => {
                  return (
                    <div>
                      <div>
                        <span className="text-red-700">
                          {ElectorType[candidate.candidateType]}
                        </span>{' '}
                        - <span className="font-bold">{candidate.name}</span> -{' '}
                        {candidate.candidateKey}
                      </div>
                      <div className="ml-10">
                        {candidate.rules.map((rule) => {
                          return (
                            <div>
                              {Field[rule.field] ?? rule.field} <span className='font-bold text-gray-600'>{Operator[rule.operator]}</span>
                              {' '} {displayParameter(rule.field, rule.parameter)}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Visor;
