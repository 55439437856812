export const TEACHER_FIELDS = [
  { field: 'code', label: 'Código UNI', value: '{{code}}' },
  { field: 'firstName', label: 'Nombres', value: '{{firstName}}' },
  {
    field: 'lastName',
    label: 'Apellidos',
    value: '{{firstLastName}} {{secondLastName}}',
  },
  { field: 'degree', label: 'Grado', value: '{{degree}}' },
  {
    field: 'category',
    label: 'Categoría',
    value: '{{category}}',
  },
  {
    field: 'serviceTimeYears',
    label: 'Tiempo de servicio',
    value: 'Años: {{serviceTimeYears}}',
  },
  {
    field: 'faculty',
    label: 'Facultad',
    value: '{{faculty}}',
  },
  {
    field: 'ageYears',
    label: 'Edad',
    value: '{{ageYears}} años',
  },
  {
    field: 'time',
    label: 'Dedicación',
    value: '{{time}} ',
  },
];

export const STUDENT_FIELDS = [
  { field: 'code', label: 'Código UNI', value: '{{code}}' },
  { field: 'firstName', label: 'Nombres', value: '{{firstName}}' },
  {
    field: 'lastName',
    label: 'Apellidos',
    value: '{{firstLastName}} {{secondLastName}}',
  },
  {
    field: 'faculty',
    label: 'Facultad',
    value: '{{faculty}}',
  },
  {
    field: 'totalApprovedCredits',
    label: 'Creditos Totales Aprobados',
    value: '{{totalApprovedCredits}}',
  },
  {
    field: 'academicPerformance',
    label: 'Tercio superior',
    value: '{{academicPerformance}}',
  },
  {
    field: 'program',
    label: 'Especialidad',
    value: '{{program}}',
  },
  {
    field: 'lastPeriod',
    label: 'Última matrícula',
    value: '{{lastPeriod}}',
  },
];

export const computeFieldValue = (record, string) => {
  console.log('record', record, string);
  const groups = [...string.matchAll(/{{([A-Za-z_]+)}}/g)];
  console.log('keys', groups);
  for (const group of groups) {
    const key = group[1];
    string = string.replace(new RegExp(`{{${key}}}`, 'g'), record[key] || '-');
  }
  return string;
};
