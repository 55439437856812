import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const Logout = () => {
  const history = useHistory();
  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
    localStorage.removeItem('role');
    history.push('/');
  }, [history]);
  return <div className="m-10">Saliendo...</div>;
};

export default Logout;
