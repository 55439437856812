import { CheckCircleIcon } from '@heroicons/react/solid';
import { useLocation, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { confirmInscription } from 'api/representative';
import { useState } from 'react';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Loader = () => {
  return (
    <svg
      className="animate-spin absolute right-2 h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const getIconStatus = (status) => {
  if (status === 'NONE') return null;
  if (status === 'LOADING') return <Loader />;
  if (status === 'OK')
    return (
      <span className="absolute right-2 inset-y-0 flex items-center pl-3">
        <CheckCircleIcon
          className="h-5 w-5 text-red-300 group-hover:text-red-400"
          aria-hidden="true"
        />
      </span>
    );
  if (status === 'ERROR') return null;
};

const ConfirmPage = () => {
  const history = useHistory();
  const query = useQuery();
  const [status, setStatus] = useState('NONE');
  const handleClick = async () => {
    try {
      setStatus('LOADING');
      const confirmationToken = query.get('confirmationToken');
      if (!confirmationToken || confirmationToken === '') {
        toast.error('Código de confirmación inválido');
      } else {
        await confirmInscription({ confirmationToken });
      }
      setStatus('OK');
      toast.success(
        'Se confirmó su inscripción con éxito, ya puede usar las credenciales brindadas',
      );
    } catch (err) {
      console.error(err);
      toast.error('Hubo un error al confirmar la inscripción');
      setStatus('ERROR');
    }
  };
  
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-60 w-auto" src="/ceuni_logo.png" alt="CEUNI UNI" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Confirmar Registro de Personeros
          </h2>
        </div>

        <div>
          <button
            onClick={handleClick}
            className={`${
              status === 'ERROR' || status === 'OK' ? 'cursor-default' : ''
            } group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700 disabled:opacity-50`}
            disabled={status === 'LOADING' || status === 'OK'}
          >
            Confirmar Registro
            {getIconStatus(status)}
          </button>
          {status === 'OK' ? (
            <button
              onClick={()=>history.push('/')}
              className="mt-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700 disabled:opacity-50"
            >
              Ir a la página principal
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ConfirmPage;
