import { format, parseISO } from 'date-fns';
import { Candidate } from 'components/molecules';

const RegisteredElectionDetails = ({ inscription }) => {
  console.log('inscription', inscription);
  const parsedDate = (date) => {
    const parsed = parseISO(date);
    return { date: format(parsed, 'dd-MM-yyyy'), hour: format(parsed, 'HH:mm') };
  };
  const { date, hour } = parsedDate(inscription.createdAt);
  return (
    <div>
      <div className="mb-3">
        <h4 className="text-2xl text-gray-800">
          Registro de la precandidatura{' '}
          <span className="font-bold px-2 py-1 text-red-900 border-4 border-red-800">
            Nro. {inscription.correlative}
          </span>
        </h4>
      </div>
      <div className="mb-5">
        Debe de haber recibido un correo de confirmación con la lista de candidatos
        inscritos
      </div>
      <div className="mb-5">
        Candidatura registrada con fecha: {date} a las {hour} horas
      </div>
      <div>
        {inscription.candidates
          .filter((candidate) => !!candidate.electorData)
          .map((candidate) => (
            <Candidate key={candidate._id} candidate={candidate} />
          ))}
      </div>
    </div>
  );
};

export default RegisteredElectionDetails;
