import { getElectoralProcesses } from 'api/electoralProcess';
import { useState, useEffect } from 'react';
import { Footer } from 'components/molecules';
import { ElectoralProcessList } from 'components/molecules';
const ProcesosElectoralesPage = () => {
  const [electoralProcesses, setElectoralProcesses] = useState({
    active: [],
    inactive: [],
  });
  useEffect(() => {
    const listarProcesos = async () => {
      const processes = await getElectoralProcesses();
      setElectoralProcesses({
        active: processes.filter((e) => new Date(e.endDate) >= new Date()),
        inactive: processes.filter((e) => new Date(e.endDate) < new Date()),
      });
    };
    listarProcesos();
  }, []);
  return (
    <div className="min-h-screen">
      <div className="mt-6 max-w-4xl mx-auto">
        <div className="flex justify-center">
          <div>
            <img className="mx-auto h-40 w-auto" src="/uni_logo.png" alt="CEUNI UNI" />
          </div>
          <div className="flex flex-col justify-center p-5">
            <p className="text-3xl text-center mb-2">COMITÉ ELECTORAL DE LA UNI</p>
          </div>
        </div>
        <div className="mb-6">
          <p className="text-2xl text-center">
            Sistema de Registro de Candidaturas a Autoridades y a Representantes ante los
            órganos de gobierno de la UNI
          </p>
        </div>
        <div className="mb-2">
          <p className="text-xl text-center">Lista de Procesos Electorales</p>
        </div>
        <div className="mx-2 border-gray-500 rounded-3xl p-5">
          <div className="text-xl">Procesos Activos</div>
          <ElectoralProcessList electoralProcesses={electoralProcesses.active} />
          {electoralProcesses.inactive.length ? (
            <>
              <div className="text-xl mt-4">Procesos Inactivos</div>
              <ElectoralProcessList electoralProcesses={electoralProcesses.inactive} />
            </>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProcesosElectoralesPage;
