import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getInscriptionAdmin } from 'api/inscriptionList';
import { Candidate } from 'components/molecules';
import { Button } from 'components/atoms';
const RepresentativeInscription = () => {
  const { representativeId } = useParams();
  const history = useHistory();
  const [inscription, setInscription] = useState(null);
  useEffect(() => {
    const init = async () => {
      const data = await getInscriptionAdmin(representativeId);
      console.log('DATA ADMIN', data);
      setInscription(data);
    };
    init();
  }, [representativeId]);
  return (
    <div>
      {inscription ? (
        <div>
          <div className="mb-5 w-1/12">
            <Button onClick={() => history.goBack()}>Volver</Button>
          </div>
          <div>
            <h4 className="text-2xl text-gray-800">
              Registro de la precandidatura{' '}
              <span className="font-bold px-2 py-1 text-red-900 border-4 border-red-800">
                Nro. {inscription.inscriptionList.correlative}
              </span>
            </h4>
            <h3 className="text-xl text-gray-800 mt-2">
              Candidatura: {inscription.joinedElections[0].electionType?.name}
            </h3>
          </div>
          <div>
            <a
              className="cursor-pointer underline text-blue-500"
              href={inscription.inscriptionList.report.url}
              target="_blank"
              rel="noreferrer"
            >
              Enlace a reporte de candidatura
            </a>
          </div>
          <div className="mt-2">
            {inscription.inscriptionList.candidates
              .filter((candidate) => !!candidate.electorData)
              .map((candidate) => (
                <Candidate key={candidate._id} candidate={candidate} />
              ))}
          </div>
        </div>
      ) : (
        'Cargando'
      )}
    </div>
  );
};

export default RepresentativeInscription;
