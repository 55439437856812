import { getProfile } from 'api/representative';
import { useEffect, useState } from 'react';
import { formatDateHour } from 'utils/dates';
const Representative = ({ title, representative }) => {
  console.log('representative', representative);
  return (
    <div>
      <div className="text-xl font-bold text-gray-800">{title}</div>
      <div>
        <div>
          <span className="text-gray-700 font-bold">Codigo UNI:</span>{' '}
          {representative.electorData.code}
        </div>
        <div>
          <span className="text-gray-700 font-bold">DNI:</span>{' '}
          {representative.electorData.dni}
        </div>
        <div>
          <span className="text-gray-700 font-bold">Nombres:</span>{' '}
          {representative.electorData.firstName}
        </div>
        <div>
          <span className="text-gray-700 font-bold">Apellidos:</span>{' '}
          {representative.electorData.firstLastName}{' '}
          {representative.electorData.secondLastName}
        </div>
        <div>
          <span className="text-gray-700 font-bold">Correo:</span>{' '}
          {representative.electorData.email}
        </div>
      </div>
    </div>
  );
};
const RepresentativeReport = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const init = async () => {
      try {
        const profile = await getProfile();
        console.log('PROFILE', profile);
        setData(profile);
      } catch (err) {
        console.error(err);
      }
    };
    init();
  }, []);
  return (
    <div className="bg-white min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center mb-6">
          <h2 className="mt-1 text-4xl font-extrabold text-red-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            CEUNI 2021
          </h2>
          <p className="max-w-xl mt-2 mx-auto text-3xl text-gray-500">
            Comité Electoral de la Universidad Nacional de Ingeniería
          </p>
          <p className="max-w-xl mt-5 mx-auto text-2xl text-gray-500">
            Reporte de inscripción de personeros
          </p>
        </div>
        {data ? <div>
          <div>Hora de Registro: {formatDateHour(data.createdAt)}</div>
          <div>Hora de Confirmación: {formatDateHour(data.confirmedAt)}</div>
        </div> : null}
        {data ? (
          <div className="grid grid-cols-1 divide-y divide-red-900">
            {data.mainRepresentative?.electorData ? (
              <Representative
                title="Personero Principal"
                representative={data.mainRepresentative}
              />
            ) : null}
            {data.alternateRepresentative?.electorData ? (
              <Representative
                title="Personero Alterno"
                representative={data.alternateRepresentative}
              />
            ) : null}
            {data.technicalRepresentative?.electorData ? (
              <Representative
                title="Personero Técnico"
                representative={data.technicalRepresentative}
              />
            ) : null}
          </div>
        ) : null}{' '}
      </div>
    </div>
  );
};
const Report = ({ type = 'REPRESENTATIVE_REPORT' }) => {
  if (type === 'REPRESENTATIVE_REPORT') {
    return <RepresentativeReport />;
  }
  return <div>No report</div>;
};

export default Report;
