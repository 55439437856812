import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL', API_URL);
const AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

AxiosInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('token');
  const accessTokenExpires = localStorage.getItem('expires');
  if (accessTokenExpires) {
    console.log(new Date(), new Date(Number(accessTokenExpires)));
    if (new Date() > new Date(Number(accessTokenExpires))) {
      console.log('removing');
      localStorage.removeItem('token');
      localStorage.removeItem('expires');
      localStorage.removeItem('role');
    }
  }
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});
export default AxiosInstance;
