const FormatsPage = () => {
  const groupFormats = [
    {
      groupName: 'Inscripción de candidatos a Rector y Vicerrectores',
      formats: [
        {
          name: '1. FICHA DE INSCRIPCIÓN - AUTORIDADES',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/rector/1.+FICHA+DE+INSCRIPCI%C3%93N+-+AUTORIDADES.pdf',
        },
        {
          name: 'A3. DECLARACIÓN JURADA - RECTOR O VICERRECTOR',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/rector/A3.+DECLARACI%C3%93N+JURADA+-+RECTOR+O+VICERRECTOR.pdf',
        },
        {
          name: 'A5. HOJA DE VIDA - DOCENTE',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/rector/A5.+HOJA+DE+VIDA+-+DOCENTE.pdf',
        },
        {
          name: 'G1. SOLICITUD DE INSCRIPCIÓN - RECTOR Y VICERRECTORES',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/rector/G1.+SOLICITUD+DE+INSCRIPCI%C3%93N+-+RECTOR+Y+VICERRECTORES.pdf',
        },
        {
          name: 'G2. PLAN DE TRABAJO DE RECTOR Y VICERRECTORES',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/rector/G2.+PLAN+DE+TRABAJO.pdf',
        },
      ],
    },
    {
      groupName: 'Inscripción de candidato a Decano',
      formats: [
        {
          name: '1. FICHA DE INSCRIPCIÓN - AUTORIDADES',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/decano/1.+FICHA+DE+INSCRIPCIO%CC%81N+-+AUTORIDADES.pdf',
        },
        {
          name: 'A3. DECLARACIÓN JURADA - DECANO',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/decano/A3.+DECLARACIO%CC%81N+JURADA+-+DECANO.pdf',
        },
        {
          name: 'A5. HOJA DE VIDA - DOCENTE',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/decano/A5.+HOJA+DE+VIDA+-+DOCENTE.pdf',
        },
        {
          name: 'G1. SOLICITUD DE INSCRIPCIÓN - DECANOS',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/decano/G1.+INSCRIPCIO%CC%81N+DE+LISTAS+-+DECANO.pdf',
        },
        {
          name: 'G2. PLAN DE TRABAJO',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/decano/G2.+PLAN+DE+TRABAJO.pdf',
        },
      ],
    },
    {
      groupName: 'Inscripción de Estudiantes a Asamblea Universitaria',
      formats: [
        {
          name: '1. FICHA DE INSCRIPCIÓN - CANDIDATURAS ESTUDIANTES',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/asamblea-universitaria-alumnos/1.+FICHA+DE+INSCRIPCI%C3%93N+-+CANDIDATURAS+ESTUDIANTES.pdf',
        },
        {
          name: 'E4. DECLARACIÓN JURADA - ESTUDIANTE',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/asamblea-universitaria-alumnos/E4.+DECLARACI%C3%93N+JURADA+-+ESTUDIANTE.pdf',
        },
        {
          name: 'E5. HOJA DE VIDA - ESTUDIANTE',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/asamblea-universitaria-alumnos/E5.+HOJA+DE+VIDA+-+ESTUDIANTE.pdf',
        },
        {
          name: 'G1. INSCRIPCIÓN DE LISTAS - ESTUDIANTES - AU',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/asamblea-universitaria-alumnos/G1.+INSCRIPCI%C3%93N+DE+LISTAS+-+ESTUDIANTES+-+AU.pdf',
        },
        {
          name: 'G2. PLAN DE TRABAJO',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/asamblea-universitaria-alumnos/G2.+PLAN+DE+TRABAJO.pdf',
        },
      ],
    },
    {
      groupName: 'Inscripción de Docentes a Consejo de Facultad',
      formats: [
        {
          name: '1. FICHA DE INSCRIPCION - CANDIDATURAS DOCENTES.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/1.+FICHA+DE+INSCRIPCION+-+CANDIDATURAS+DOCENTES.pdf',
        },
        {
          name: 'D2. DECLARACION JURADA - DOCENTE.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/D2.+DECLARACION+JURADA+-+DOCENTE.pdf',
        },
        {
          name: 'D4. HOJA DE VIDA - DOCENTE.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/D4.+HOJA+DE+VIDA+-+DOCENTE.pdf',
        },
        {
          name: 'G1. INSCRIPCION DE LISTAS - DOCENTES - CF - AS.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/G1.+INSCRIPCIO%CC%81N+DE+LISTAS+-+DOCENTES+CF+-+AS.pdf',
        },
        {
          name: 'G1. INSCRIPCION DE LISTAS - DOCENTES - CF - AX.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/G1.+INSCRIPCIO%CC%81N+DE+LISTAS+-+DOCENTES+CF+-+AX.pdf',
        },
        {
          name: 'G1. INSCRIPCION DE LISTAS - DOCENTES - CF - PR.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/G1.+INSCRIPCIO%CC%81N+DE+LISTAS+-+DOCENTES+CF+-+PR.pdf',
        },
        {
          name: 'G2. PLAN DE TRABAJO.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/docentes-consejo-facultad/G2.+PLAN+DE+TRABAJO.pdf',
        },
      ],
    },
    {
      groupName: 'Inscripción de Estudiantes a Consejo de Facultad',
      formats: [
        {
          name: '1. FICHA DE INSCRIPCIÓN - CANDIDATURAS ALUMNOS.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/estudiantes-consejo-facultad/1.+FICHA+DE+INSCRIPCIO%CC%81N+-+CANDIDATURAS+ALUMNOS.pdf',
        },
        {
          name: 'E4. DECLARACIÓN JURADA - ESTUDIANTE.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/estudiantes-consejo-facultad/E4.+DECLARACIO%CC%81N+JURADA+-+ESTUDIANTE.pdf',
        },
        {
          name: 'E5. HOJA DE VIDA - ESTUDIANTE.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/estudiantes-consejo-facultad/E5.+HOJA+DE+VIDA+-+ESTUDIANTE.pdf',
        },
        {
          name: 'G1. INSCRIPCIÓN DE LISTAS - ESTUDIANTES - CF.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/estudiantes-consejo-facultad/G1.+INSCRIPCIO%CC%81N+DE+LISTAS+-+ESTUDIANTES+-+CF.pdf',
        },
        {
          name: 'G2. PLAN DE TRABAJO.pdf',
          url: 'https://inscripciones-ceuni.s3.amazonaws.com/formatos/estudiantes-consejo-facultad/G2.+PLAN+DE+TRABAJO.pdf',
        },
      ],
    },
  ];
  return (
    <div>
      <div className="text-3xl mb-3">Formatos</div>
      <div>
        {groupFormats.map((groupFormat) => {
          return (
            <div
              key={groupFormat.groupName}
              className="bg-white overflow-hidden rounded-lg p-5 shadow-xl mb-5"
            >
              <div className="text-xl font-bold text-gray-800">
                {groupFormat.groupName}
              </div>
              {groupFormat.formats.map((f) => {
                return (
                  <div key={f.name}>
                    <div> - {f.name}</div>
                    <div>
                      <a
                        href={f.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 "
                      >
                        Click para abrir el formato
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormatsPage;
