import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatDateHour = (date) => {
  return format(parseISO(date), 'dd-MM-yyyy HH:mm');
};
export const formatDateHourShort = (date) => {
  return format(parseISO(date), 'dd-MM HH:mm');
};
export const formatDateHourDetailed = (date) => {
  return format(parseISO(date), 'dd MMMM yyyy hh:mm aaaa', { locale: es });
};
export const formatDate = (date) => {
  return format(parseISO(date), 'dd-MM-yyyy');
};

export const formatHour = (date) => {
  return format(parseISO(date), 'HH:mm');
};

export const parseDate = (date) => {
  return parseISO(date);
};
