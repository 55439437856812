import axios from './index';

export const getElector = async ({ code, email, electoralProcessCode }) => {
  try {
    const { data } = await axios.get(`/elector`, {
      params: {
        code,
        email,
        electoralProcessCode,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getElectorCandidate = async ({ code }) => {
  try {
    const { data } = await axios.get(`/elector/candidate`, {
      params: {
        code,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getElectorPublic = async ({
  code,
  email,
  electoralProcessCode,
  recaptcha,
}) => {
  try {
    const { data } = await axios.get(`/elector/public`, {
      params: {
        code: code.trim(),
        email: email.trim(),
        electoralProcessCode,
        recaptcha,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
