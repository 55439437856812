import { useEffect, useCallback, useState } from 'react';
import { Button, InputText, InputFile } from 'components/atoms';
import { Formik, Form, Field } from 'formik';
import toast from 'react-hot-toast';
import Countdown from 'react-countdown';
import { getElectorCandidate } from 'api/elector';
import { submitInscription } from 'api/inscriptionList';
import ElectionItem from './electionItem';
import ElectionSelector from './electionSelector';
import RegisteredElectionDetails from './registeredElectionDetails';
import ErrorPanel from './errorPanel';
import { getInscription } from 'api/inscriptionList';
import { getProfile } from 'api/representative';
import { Modal } from 'components/molecules';
import { getElections, getElectionById } from 'api/election';
import { parseDate, formatDateHourDetailed } from 'utils/dates';
import { TEACHER_FIELDS, STUDENT_FIELDS, computeFieldValue } from './consts';

const renderAtomicTime = (time, type) => {
  const timeTypes = {
    DAY: ['días', 'día'],
    HOUR: ['horas', 'hora'],
    MINUTE: ['minutos', 'minuto'],
    SECOND: ['segundos', 'segundo'],
  };
  if (time <= 0) return '';
  if (time === 1) return `${time} ${timeTypes[type][1]}`;
  return `${time} ${timeTypes[type][0]}`;
};
const rendererTimeLeft = ({ days, hours, minutes, seconds, completed }) => {
  console.log('days', days, hours, minutes, seconds);
  if (completed) {
    return <div>Refresca la página!</div>;
  } else {
    // Render a countdown
    return (
      <span>
        Podrás registrar en {renderAtomicTime(days, 'DAY')}{' '}
        {renderAtomicTime(hours, 'HOUR')} {renderAtomicTime(minutes, 'MINUTE')}{' '}
        {renderAtomicTime(seconds, 'SECOND')}
      </span>
    );
  }
};

const ElectionInput = ({ input, name, setFieldValue, electionId, candidateKey, url }) => {
  let Component;
  if (input.inputType === 'FILE') {
    Component = (
      <InputFile
        label={input.label}
        name={name}
        setFieldValue={setFieldValue}
        electionId={electionId}
        candidateKey={candidateKey}
        url={url}
        mimeTypes={input.mimeTypes}
      />
    );
  } else if (input.inputType === 'CHECK') {
    Component = (
      <div className="font-medium text-gray-700">
        <div className="block text-sm">{input.label}</div>
        <div className="flex flex-row">
          <div>
            <Field type="radio" name={`${name}.value`} value="TRUE" />
            <span className="ml-3">Sí</span>
          </div>
          <div className="ml-5">
            <Field type="radio" name={`${name}.value`} value="FALSE" />
            <span className="ml-3">No</span>
          </div>
        </div>
      </div>
    );
  } else {
    Component = (
      <Field
        type="text"
        name={`${name}.value`}
        component={InputText}
        label={input.label}
      />
    );
  }
  return <div>{Component}</div>;
};
const FormElection = ({ electionCore }) => {
  console.log('electionCore', electionCore);
  const [errors, setErrors] = useState({ errors: [], generalErrors: [] });
  const [displayModal, setDisplayModal] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [validating, setValidating] = useState(false);
  const handleSubmit = async () => {
    setValidating(true);
    try {
      setDisplayModal(false);
      const response = await submitInscription(formValues);
      console.log('response', response);
      toast.success('Candidatura registrada con éxito!');
      document.location.reload();
    } catch (err) {
      console.error('Error form election', err);
      setErrors({
        errors: err?.errors || [],
        generalErrors: err?.generalErrors || [],
      });
      toast.error('Error al inscribir candidatura');
    }
    setValidating(false);
  };
  const validateRepresentative = async (code, key, setFieldValue) => {
    let fields;
    try {
      const elector = await getElectorCandidate({
        code,
      });
      fields = elector.type === 'TEACHER' ? TEACHER_FIELDS : STUDENT_FIELDS;
      console.log('fields', fields);
      fields.forEach((record) => {
        setFieldValue(`${key}.${record.field}`, computeFieldValue(elector, record.value));
      });
    } catch (err) {
      console.error('ERROR:', err);
      if (fields)
        fields.forEach((record) => {
          setFieldValue(`${key}.${record.field}`, '');
        });
      toast.error('No se encontró en el padrón');
    }
  };
  const initialValues = {
    candidates: electionCore.candidates.map((candidate) => {
      const inputs = {
        DNI: {
          value: '',
          firstName: '',
          lastName: '',
        },
      };
      candidate.inputs.forEach((input) => {
        inputs[input.inputKey] = { value: '' };
      });
      return {
        candidateKey: candidate.candidateKey,
        inputs,
      };
    }),
  };
  console.log('initialValues', initialValues);

  return (
    <div className="border border-gray-300 rounded-3xl p-5 bg-white mb-2">
      {errors.generalErrors.length ? (
        <ErrorPanel errors={errors.generalErrors} type="GENERAL_ERRORS" />
      ) : null}
      {errors.errors.length ? (
        <ErrorPanel errors={errors.errors} type="CANDIDATES" />
      ) : null}

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          setDisplayModal(true);
          setFormValues(values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {electionCore.candidates.map((candidate, i) => {
              return (
                <div className="mb-3" key={candidate.name}>
                  <div>{candidate.name}</div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
                    <div className="grid grid-cols-12 gap-x-2 gap-y-1 col-span-3">
                      <div className="grid grid-cols-6 gap-x-2 sm:grid-cols-12 col-span-12 mb-2">
                        <div className="col-span-2 sm:col-span-2">
                          <Field
                            label="DNI"
                            type="text"
                            name={`candidates.${i}.inputs.DNI.value`}
                            component={InputText}
                          />
                        </div>
                        <div className="col-span-1 sm:col-span-2 flex items-end">
                          <button
                            type="button"
                            onClick={() =>
                              validateRepresentative(
                                values.candidates[i].inputs.DNI.value,
                                `candidates.${i}.inputs.DNI`,
                                setFieldValue,
                              )
                            }
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700"
                          >
                            Consultar
                          </button>
                        </div>
                      </div>
                      {(candidate.candidateType === 'TEACHER'
                        ? TEACHER_FIELDS
                        : STUDENT_FIELDS
                      ).map((record) => {
                        return (
                          <div key={record.field} className="col-span-4">
                            <Field
                              type="text"
                              name={`candidates.${i}.inputs.DNI.${record.field}`}
                              component={InputText}
                              label={record.label}
                              disabled
                            />
                          </div>
                        );
                      })}
                    </div>

                    {candidate.inputs
                      .filter((input) => input.inputType !== 'FILE')
                      .map((input) => {
                        return (
                          <ElectionInput
                            key={input.label}
                            name={`candidates.${i}.inputs.${input.inputKey}`}
                            input={input}
                            url={values.candidates[i].inputs[input.inputKey].url}
                            setFieldValue={setFieldValue}
                            electionId={electionCore._id}
                            candidateKey={candidate.candidateKey}
                          />
                        );
                      })}

                    {candidate.inputs
                      .filter((input) => input.inputType === 'FILE')
                      .map((input) => {
                        return (
                          <ElectionInput
                            key={input.label}
                            name={`candidates.${i}.inputs.${input.inputKey}`}
                            input={input}
                            url={values.candidates[i].inputs[input.inputKey].url}
                            setFieldValue={setFieldValue}
                            electionId={electionCore._id}
                            candidateKey={candidate.candidateKey}
                          />
                        );
                      })}
                  </div>
                  <div></div>
                </div>
              );
            })}
            <Button type="submit" status={validating ? 'LOADING' : null}>
              Validar y Registrar Candidaturas
            </Button>
          </Form>
        )}
      </Formik>
      {errors.generalErrors.length ? (
        <ErrorPanel errors={errors.generalErrors} type="GENERAL_ERRORS" />
      ) : null}
      {errors.errors.length ? (
        <ErrorPanel errors={errors.errors} type="CANDIDATES" />
      ) : null}
      <Modal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
        handleConfirm={handleSubmit}
        title="Confirmación de inscripción"
        description="Estás seguro que quiere registrar esta candidatura?"
      />
    </div>
  );
};

const ElectionPage = () => {
  const [joinedElection, setJoinedElection] = useState(null);
  const [inscription, setInscription] = useState(null);
  const [elections, setElections] = useState([]);
  const [inscriptionDate, setInscriptionDate] = useState({
    startDate: null,
    endDate: null,
    start: null,
    end: null,
    statusSubmit: null,
  });
  const fillElections = async () => {
    try {
      const data = await getElections();
      console.log("getting elections", data)
      setElections(data);
    } catch (err) {
      console.error(err);
    }
  };
  const checkForJoinedElections = useCallback(async () => {
    try {
      const { joinedElections, electoralProcess } = await getProfile();
      const { inscriptionStartDate, inscriptionEndDate } = electoralProcess;
      const inscriptionStartDateParsed = parseDate(inscriptionStartDate);
      const inscriptionEndDateParsed = parseDate(inscriptionEndDate);
      let statusSubmit;
      if (new Date() < inscriptionStartDateParsed) {
        statusSubmit = 'WAITING';
      } else if (new Date() <= inscriptionEndDateParsed) {
        statusSubmit = 'ON_TIME';
      } else {
        statusSubmit = 'OUT_OF_TIME';
      }
      console.log('statusSubmit', statusSubmit);

      setInscriptionDate({
        statusSubmit,
        startDate: inscriptionStartDate,
        start: formatDateHourDetailed(inscriptionStartDate),
        endDate: inscriptionEndDate,
        end: formatDateHourDetailed(inscriptionEndDate),
      });
      console.log('statusSubmit', statusSubmit);
      if (statusSubmit === 'ON_TIME') {
        if (joinedElections?.length > 0) {
          const election = await getElectionById(joinedElections[0]._id);
          setJoinedElection(election);
        } else {
          console.log("fillin elections")
          fillElections();
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);
  useEffect(() => {
    const init = async () => {
      try {
        const inscriptionData = await getInscription();
        setInscription(inscriptionData);
      } catch (err) {
        if (err?.code === 'INSCRIPTION_NOT_FOUND') {
          await checkForJoinedElections();
        }
        console.error(err);
      }
    };
    init();
  }, [checkForJoinedElections]);
  return (
    <div>
      <div>
        {inscription ? (
          <RegisteredElectionDetails inscription={inscription} />
        ) : (
          <>
            {inscriptionDate.statusSubmit === 'ON_TIME' ? (
              <>
                {joinedElection ? (
                  <>
                    <ElectionItem election={joinedElection} />
                    <FormElection electionCore={joinedElection.electionType} />
                  </>
                ) : (
                  <ElectionSelector
                    elections={elections}
                    checkForJoinedElections={checkForJoinedElections}
                  />
                )}
              </>
            ) : (
              <>
                {inscriptionDate.statusSubmit !== null ? (
                  <div>
                    <div className="text-2xl text-red-700 font-bold mb-3">
                      Aún no puede registrar candidaturas.
                    </div>
                    <div className="text-body text-red-800 font-mono">
                      <div>Inicio: {inscriptionDate.start}</div>
                      <div>Fin: {inscriptionDate.end}</div>
                      {inscriptionDate.statusSubmit === 'WAITING' ? (
                        <Countdown
                          daysInHours={true}
                          date={inscriptionDate.startDate}
                          renderer={rendererTimeLeft}
                        />
                      ) : (
                        <div>Ya venció el plazo para inscribir candidaturas</div>
                      )}
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ElectionPage;
